@keyframes saving-just-saved {
  from {opacity :0;}
  10% {opacity :1;}
  80% {opacity :1;}
  to {opacity :0;}
}

.saving-just-saved {
  animation:saving-just-saved 1s 1;
  animation-fill-mode: forwards;
}

@keyframes saving-is-dirty{
  from {opacity :0;}
  to {opacity :1;}
}
  
.saving-is-dirty {
  animation:saving-is-dirty 0.5s 1;
  animation-fill-mode: forwards;
  animation-delay:0s;
}

.gg-spinner-two-custom {
  transform: scale(var(--ggs,1));
  box-sizing: border-box;
  position: relative;
  display: block;
  width: 20px;
  height: 20px
}

.gg-spinner-two-custom::after,
.gg-spinner-two-custom::before {
  box-sizing: border-box;
  display: block;
  width: 20px;
  height: 20px;
  content: "";
  position: absolute;
  border-radius: 100px
}

.gg-spinner-two-custom::before {
  border: 3px solid transparent;
  border-bottom-color: currentColor;
  border-top-color: currentColor
}

.gg-spinner-two-custom::after {
  border: 3px solid;
  border-bottom-color: currentColor;
  border-top-color: currentColor;
  opacity: .2
}